body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #111;
  position: relative;
  height: 100vh;
  overflow: hidden;
}

html {
  height: 100vh;
  overflow: hidden;
}

main {
  width: 400px;
  display: flex;
  justify-content: space-evenly;
  height: 100vh;
  align-items: flex-end;
}

.star {
  font-size: 100px;
  cursor: pointer;
}

aside {
  color: gold;
  height: 100vh;
  overflow-y: auto;
  padding: 0 20px;
}

.star-history {
  font-size: 20px;
}

.star-history-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 50vw;
}